import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { Box, Button, TextField } from '@mui/material';

import CategoryIconWidget from '~/components/Common/Forms/widgets/CategoryIconWidget';
import Spinner from '~/components/Common/Spinner';

import dealPageService from '~/services/cruises/DealPageService';

type FormInput = {
  description: string;
  icon?: string;
  isHighlighted?: boolean;
};

type Props = {
  dealPageId: string;
  onSave: () => void;
};

function DealInclusionForm({ dealPageId, onSave }: Props) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    formState: { isSubmitting, isValid },
    setValue,
    handleSubmit,
  } = useForm({
    defaultValues: {
      description: '',
      isHighlighted: false,
    },
  });

  const onSubmit = useCallback(
    async (data: FormInput) => {
      try {
        await dealPageService.createDealInclusion(dealPageId, data);

        enqueueSnackbar('Inclusion saved with success', { variant: 'success' });
        onSave();
        history.push(`/cruises/deals/${dealPageId}/edit/inclusions`);
      } catch (error) {
        enqueueSnackbar('Error saving inclusion', { variant: 'error' });
      }
    },
    [history, enqueueSnackbar, dealPageId, onSave],
  );

  const handleCancel = useCallback(() => {
    history.push(`/cruises/deals/edit/${dealPageId}/inclusions`);
  }, [history, dealPageId]);

  const isSaveEnabled = !isSubmitting && isValid;

  return (
    <Box>
      <Box mt={2}>
        <CategoryIconWidget control={control} label="Icon" name="icon" setValue={setValue} />
      </Box>

      <Box mt={2}>
        <Controller
          name="description"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState }) => (
            <TextField
              label="Description"
              name={field.name}
              ref={field.ref}
              value={field.value}
              error={fieldState.invalid}
              onChange={field.onChange}
              onBlur={field.onBlur}
              fullWidth
            />
          )}
        />
      </Box>

      <Box mt={3} gap={1} display="flex" justifyContent="end">
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>

        <Button variant="contained" onClick={handleSubmit(onSubmit)} disabled={!isSaveEnabled}>
          {isSubmitting && <Spinner size={15} />}
          Save Changes
        </Button>
      </Box>
    </Box>
  );
}

export default DealInclusionForm;
