import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router';
import MUIProvider from '~/contexts/MUIProvider';

import { Box, Button, TextField, Typography } from '@mui/material';

import Layout from '~/components/layouts/Layout';

function CruiseInvalidOffers() {
  const [offerId, setOfferId] = useState<string>();
  const history = useHistory();

  const handleSubmit = useCallback(async () => {
    history.push(`/cruises/offer/${offerId}?allowInvalidOffer=true`);
  }, [offerId, history]);

  return (
    <MUIProvider>
      <Layout
        header={
          <Typography variant="h4" gutterBottom>
            Validate Offer Issues
          </Typography>
        }
      >
        <TextField
          required
          fullWidth
          name="name"
          label="Enter an offer ID"
          onChange={(e) => setOfferId(e.target.value)}
          value={offerId}
        />

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={handleSubmit} disabled={!offerId}>
            Check offer
          </Button>
        </Box>
      </Layout>
    </MUIProvider>
  );
}

export default CruiseInvalidOffers;
