import React, { useCallback, useEffect, useMemo, useState } from 'react';

import debounce from 'lodash/debounce';

import { Autocomplete, Chip, FormControl, Stack, TextField } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import Spinner from '~/components/Common/Spinner';
import { Option, Request } from '~/components/Cruises/pages/Promotions/types';

import rateCodeService, { GetRateCodes } from '~/services/cruises/RateCodeService';

import { isNumeric } from '~/utils/stringUtils';

const RATE_CODE_LIMIT = 500;

interface Props {
  selectedRateIds: Array<string>;
  selectedRateCodes?: Array<string>;
  onChange: (selectedRateIds: Array<string>) => void;
  vendorCode?: string;
  isLoading?: boolean;
  allowEmptyVendorCode?: boolean;
  shouldReturnRateCodes?: boolean;
}

function InputRateCodeSelect({
  selectedRateIds,
  selectedRateCodes,
  isLoading,
  onChange,
  vendorCode,
  allowEmptyVendorCode,
  shouldReturnRateCodes,
}: Props) {
  const [loadedVendorCode, setLoadedVendorCode] = useState<string | null>(null);
  const [currentSelectedRateCodes, setCurrentSelectedRateCodes] = useState<Array<string>>([]);
  const [newSelectedRateCodes, setNewSelectedRateCodes] = useState<Array<GetRateCodes>>([]);
  const [cruiseRateCodes, setCruiseRateCodes] = useState<Request<Array<GetRateCodes>>>({
    result: [],
    loading: false,
  });
  const ratePropReturned = shouldReturnRateCodes ? 'label' : 'value';

  const fetchRateCodes = useCallback(
    async (rateCode?: string) => {
      const isRateCodeNumeric = isNumeric(rateCode);
      if ((!rateCode || isRateCodeNumeric) && !vendorCode && !allowEmptyVendorCode) {
        return;
      }

      setCruiseRateCodes((prev) => ({ ...prev, loading: true }));
      const res = await rateCodeService.getRateCodeList({
        take: RATE_CODE_LIMIT,
        vendorCode,
        rateCodes: rateCode?.split(',') || [],
      });

      setLoadedVendorCode(vendorCode);
      setCruiseRateCodes({ loading: false, result: res.result });
    },
    [allowEmptyVendorCode, vendorCode],
  );

  useEffect(() => {
    if (allowEmptyVendorCode || (!!vendorCode && loadedVendorCode !== vendorCode)) {
      fetchRateCodes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorCode]);

  const handleSelectedRateCodes = useCallback(async () => {
    const newSelectedRateCodesSet = new Set(newSelectedRateCodes.flatMap((item) => item.code));
    const rateCodesToFetch = currentSelectedRateCodes
      ?.filter((rateCode) => !newSelectedRateCodesSet.has(rateCode))
      .filter(Boolean);

    if (!rateCodesToFetch?.length) {
      return;
    }

    const res = await rateCodeService.getRateCodeList({
      take: RATE_CODE_LIMIT,
      vendorCode,
      rateCodes: rateCodesToFetch,
    });

    setNewSelectedRateCodes((prev) => [...prev, ...res.result]);
  }, [currentSelectedRateCodes, newSelectedRateCodes, vendorCode]);

  // Set selectedRateCodes to currentSelectedRateCodes when selectedRateCodes is changed
  useEffect(() => {
    if (selectedRateCodes?.length > 0) {
      setCurrentSelectedRateCodes(selectedRateCodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRateCodes?.length]);

  useEffect(() => {
    if (currentSelectedRateCodes) {
      handleSelectedRateCodes();
    }
  }, [handleSelectedRateCodes, currentSelectedRateCodes]);

  // Reset state when vendorCode changes
  useEffect(() => {
    if (loadedVendorCode && vendorCode !== loadedVendorCode) {
      setNewSelectedRateCodes([]);
      onChange([]);
    }
    setLoadedVendorCode(vendorCode);
  }, [vendorCode, loadedVendorCode, onChange]);

  const ratesSelectOptions = useMemo(() => {
    return cruiseRateCodes.result
      ?.filter((item) => !selectedRateIds.includes(item.id))
      .map((item) => ({
        value: item.id,
        label: item.code,
      }));
  }, [cruiseRateCodes.result, selectedRateIds]);

  const ratesSelected = useMemo(() => {
    return newSelectedRateCodes.map((item) => ({
      label: item.code,
      value: item.id,
    }));
  }, [newSelectedRateCodes]);

  const handleRatesChange = useCallback(
    (_, selectedOptions: Array<Option> | null) => {
      onChange(
        selectedOptions?.map((selectedRate) => {
          return selectedRate[ratePropReturned];
        }),
      );
      setNewSelectedRateCodes((prevState) => {
        if (!selectedOptions) {
          return prevState;
        }
        return selectedOptions
          .map((selectedRate) => ({
            id: selectedRate.value,
            code: selectedRate.label,
          }))
          .filter((item) => !!item) as Array<API.RateCodeListResponse>;
      });

      // Updating currentSelectedRateCodes to avoid fetching rate codes that are already selected/removed
      setCurrentSelectedRateCodes(selectedOptions?.map((selectedRate) => selectedRate.value));
    },
    [onChange, ratePropReturned],
  );

  const debouncedFetchRateCodes = useMemo(() => debounce(fetchRateCodes, 500), [fetchRateCodes]);
  const isDisabled = !allowEmptyVendorCode && !vendorCode;
  const isLoadingRateCodes = cruiseRateCodes.loading || isLoading;

  return (
    <Stack spacing={2} direction="row">
      <FormControl fullWidth>
        <Autocomplete
          id="select-rates"
          multiple
          selectOnFocus
          clearOnBlur={false}
          disableCloseOnSelect
          disabled={isDisabled || isLoading}
          loading={cruiseRateCodes.loading || isLoading}
          value={ratesSelected}
          onChange={handleRatesChange}
          options={ratesSelectOptions}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                variant="outlined"
                key={option.value}
                label={option.label}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} fullWidth label="Rate Codes" placeholder="Please Enter Rate Codes" />
          )}
          onInputChange={(_, value) => debouncedFetchRateCodes(value)}
        />
      </FormControl>
      {isLoadingRateCodes && <Spinner size={20} />}
    </Stack>
  );
}

export default InputRateCodeSelect;
