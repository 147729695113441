import React, { useCallback, useEffect, useState } from 'react';

import { DndContext } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { useSnackbar } from 'notistack';
import { Link, useParams } from 'react-router-dom';

import { Box, Button, Stack, Typography } from '@mui/material';
import List from '@mui/material/List';

import { CruiseDealInclusion } from '~/components/Cruises/pages/DealsPage/types';

import dealPageService from '~/services/cruises/DealPageService';

import DealInclusionCreate from './DealInclusionCreate';
import DealInclusionListItem from './DealInclusionListItem';

function InclusionsItemList() {
  const [inclusionItems, setDealInclusions] = useState<Array<CruiseDealInclusion>>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { id: dealPageId, action } = useParams<{ id: string; action?: string }>();

  const fetchDealInclusions = useCallback(async () => {
    const response = await dealPageService.getDealInclusions(dealPageId);
    setDealInclusions(response.result);
  }, [dealPageId, setDealInclusions]);

  const handleDragSort = useCallback(
    async ({ active, over }) => {
      if (!!over && active.id !== over.id) {
        const oldIndex = inclusionItems.map((e) => e.id).indexOf(active.id);
        const newIndex = inclusionItems.map((e) => e.id).indexOf(over.id);

        const newList = arrayMove(inclusionItems, oldIndex, newIndex).map((inclusion, index) => ({
          ...inclusion,
          order: index,
        }));

        const maxHighlightedInclusionOrder = Math.max(
          ...newList.filter((inclusion) => inclusion.isHighlighted).map((inclusion) => inclusion.order),
        );

        const hasNormalInclusionBeforeHighlightedInclusions = newList.some(
          (inclusion) => !inclusion.isHighlighted && inclusion.order < maxHighlightedInclusionOrder,
        );

        if (hasNormalInclusionBeforeHighlightedInclusions) {
          enqueueSnackbar('Highlighted inclusions should be at the top of the list', { variant: 'error' });
          return;
        }

        setDealInclusions(newList);

        try {
          await dealPageService.sortDealInclusions(
            dealPageId,
            newList.map((inclusion) => inclusion.id),
          );
          enqueueSnackbar('Inclusions updated', { variant: 'success' });
        } catch (error) {
          enqueueSnackbar('Error updating Inclusions', { variant: 'error' });
        }
      }
    },
    [inclusionItems, setDealInclusions],
  );

  useEffect(() => {
    fetchDealInclusions();
  }, [fetchDealInclusions]);

  return (
    <Box border={1} borderColor="divider" borderRadius={1}>
      <Box padding={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography fontWeight="bold">Inclusions</Typography>
          <Button
            component={Link}
            to={`/cruises/deals/${dealPageId}/edit/inclusions/create`}
            variant="outlined"
            size="small"
          >
            Add Inclusion
          </Button>
        </Stack>
      </Box>

      <Box borderTop={1} borderColor="divider">
        <List>
          <DndContext onDragEnd={handleDragSort}>
            <SortableContext items={inclusionItems || []}>
              {inclusionItems.map((inclusion) => (
                <DealInclusionListItem key={inclusion.id} inclusion={inclusion} onChange={fetchDealInclusions} />
              ))}

              {!inclusionItems.length && (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  <Typography variant="body1" color="text.secondary">
                    No inclusions added
                  </Typography>
                </Box>
              )}
            </SortableContext>
          </DndContext>
        </List>

        {action === 'create' && <DealInclusionCreate onSave={fetchDealInclusions} />}
      </Box>
    </Box>
  );
}

export default InclusionsItemList;
