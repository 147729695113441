import React, { useMemo } from 'react';

import pluralize from 'pluralize';
import { useLocation } from 'react-router';

import AddLocationAltIcon from '@mui/icons-material/AddLocationAltOutlined';
import ListAltIcon from '@mui/icons-material/ListAltOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOnOutlined';
import { Alert, AlertTitle, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

const ICONS = {
  departures: <AddLocationAltIcon />,
  itineraries: <ListAltIcon />,
  prices: <MonetizationOnIcon />,
};

interface Props {
  offer: API.Offer;
}

const CruiseOfferInvalidDetails = ({ offer }: Props) => {
  const { search } = useLocation();

  const allowInvalidOffer = useMemo(() => {
    const params = new URLSearchParams(search);
    return !!params.get('allowInvalidOffer');
  }, [search]);

  const departures = useMemo(() => {
    return Object.values(offer.departures || {}).flat();
  }, [offer]);

  const criticalItems = useMemo(() => {
    const invalidItems: {
      icon: React.ReactNode;
      text: string;
      secondary?: string;
    }[] = [];

    if (!departures.length) {
      invalidItems.push({
        icon: ICONS.departures,
        text: 'There are no departures for this offer',
      });
    }

    if (!offer.itineraries?.length) {
      invalidItems.push({
        icon: ICONS.itineraries,
        text: 'There are no itineraries for this offer',
      });
    }

    const departuresWithPrices = departures.filter((departure) => !!departure.prices?.length);

    if (!departuresWithPrices.length) {
      invalidItems.push({
        icon: ICONS.prices,
        text: 'There are no departures with prices for this offer',
        secondary:
          'Change the offer status to "Draft" first, then to "Content-Approved" to fix this. (it may take a few minutes)',
      });
    }

    return invalidItems;
  }, [offer, departures]);

  const warningItems = useMemo(() => {
    const warningItems: {
      icon: React.ReactNode;
      text: string;
      secondary?: string;
    }[] = [];

    const departuresWithoutPrices = departures.filter((departure) => !departure.prices?.length);

    if (departuresWithoutPrices.length) {
      warningItems.push({
        icon: ICONS.prices,
        text: `There are ${departuresWithoutPrices.length} ${pluralize(
          'departure',
          departuresWithoutPrices.length,
        )} without prices for this offer`,
      });
    }

    return warningItems;
  }, [offer, departures]);

  if (!allowInvalidOffer) {
    return null;
  }

  return (
    <>
      {!!criticalItems.length && (
        <Alert severity="error" icon={false}>
          <AlertTitle>This offer is invalid due to the following problems:</AlertTitle>
          <List>
            {criticalItems.map((item) => (
              <ListItem key={item.text}>
                <ListItemIcon sx={{ color: 'error.light', minWidth: 35 }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} secondary={item.secondary} />
              </ListItem>
            ))}
          </List>
        </Alert>
      )}

      {!criticalItems.length && !!warningItems.length && (
        <Alert severity="warning" icon={false}>
          <AlertTitle>This offer is valid but has some problems:</AlertTitle>
          <List>
            {warningItems.map((item) => (
              <ListItem key={item.text}>
                <ListItemIcon sx={{ color: 'warning.light', minWidth: 35 }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} secondary={item.secondary} />
              </ListItem>
            ))}
          </List>
        </Alert>
      )}

      {!criticalItems.length && !warningItems.length && (
        <Alert severity="success">
          <AlertTitle>This offer is valid</AlertTitle>
        </Alert>
      )}
    </>
  );
};

export default CruiseOfferInvalidDetails;
