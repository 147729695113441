import React, { useMemo, useState } from 'react';

import currencyFormatter from 'currency-formatter';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import { useCruiseOffers } from '~/components/Cruises/context/useCruiseOffers';
import { withTenant } from '~/components/hoc';

import { formatDateNumeralMonths, formatDateSlashes, sortByDate } from '~/services/TimeService';
import { customerPortalHost } from '~/services/common';
import cruiseOfferService from '~/services/cruises/OfferService';

const urlBuilder = (id): string => `/cruises/offer/${id}`;

interface Props {
  tenant: App.Tenant;
}

const CruisesList: React.FC<Props> = ({ tenant }): JSX.Element => {
  const { page, total, paginate, offers, loadingOffers, cruisesPerPage } = useCruiseOffers();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'names',
        headerName: 'Name',
        width: 200,
        editable: true,
        renderCell: (params) => (
          <Tooltip
            title={`${params.value.name}
            ${params.value.displayName ? ', ' + params.value.displayName : ''}`}
          >
            <Typography variant="inherit" noWrap>
              <Link to={urlBuilder(params.value.id)}>
                <Typography variant="inherit" noWrap>
                  {params.value.name}
                </Typography>
                {params.value.displayName && (
                  <Typography variant="inherit" noWrap>
                    {params.value.displayName}
                  </Typography>
                )}
              </Link>
            </Typography>
          </Tooltip>
        ),
        sortComparator: (names1: any, names2: any) => {
          return names1.name.localeCompare(names2.name);
        },
        display: 'flex',
      },
      {
        field: 'marketNames',
        headerName: 'Market names',
        width: 200,
        editable: true,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value?.join(', ')}>
              <Typography variant="inherit" noWrap>
                {params.value?.join(', ')}
              </Typography>
            </Tooltip>
          );
        },
        display: 'flex',
      },
      {
        width: 150,
        field: 'shipName',
        headerName: 'Ship Name',
        editable: true,
        display: 'flex',
      },
      {
        field: 'departurePort',
        headerName: 'Departure Port',
        editable: true,
        width: 150,
        renderCell: (params) => {
          return (
            <div>
              <Typography>{params.value?.name}</Typography>
            </div>
          );
        },
        sortComparator: (departurePort1: any, departurePort2: any) => {
          return departurePort1.name.localeCompare(departurePort2.name);
        },
        display: 'flex',
      },
      {
        field: 'departurePortsOfCall',
        width: 200,
        headerName: 'Ports of Call',
        editable: true,
        renderCell: (params) => {
          return (
            <Tooltip title={params.value?.join(', ')}>
              <Typography variant="inherit" noWrap>
                {params.value?.join('| ')}
              </Typography>
            </Tooltip>
          );
        },
        sortComparator: (portsOfCall1: string[], portsOfCall2: string[]) => {
          const p1 = [...portsOfCall1].sort((a, b) => a.localeCompare(b))[0];
          const p2 = [...portsOfCall2].sort((a, b) => a.localeCompare(b))[0];
          return p1.localeCompare(p2);
        },
        display: 'flex',
      },
      {
        field: 'departureDates',
        headerName: 'Departure Dates',
        editable: true,
        width: 150,
        renderCell: (params) => {
          const value = params.value?.map((date) => formatDateNumeralMonths(date)).join(', ');
          return (
            <Tooltip title={value}>
              <Typography variant="inherit" noWrap>
                {value}
              </Typography>
            </Tooltip>
          );
        },
        sortComparator: (dates1: any, dates2: any) => {
          const d1 = [...dates1].map(formatDateSlashes).sort(sortByDate)[0];
          const d2 = [...dates2].map(formatDateSlashes).sort(sortByDate)[0];
          return sortByDate(d1, d2);
        },
        display: 'flex',
      },
      {
        width: 100,
        field: 'status',
        headerName: 'Status',
        editable: true,
        display: 'flex',
      },
      {
        field: 'lowestDeparturePrice',
        headerName: 'From Price',
        editable: true,
        renderCell: (params) => {
          return (
            <div>
              <Typography>
                {params.value?.adultFare
                  ? currencyFormatter.format(params.value?.adultFare, {
                      code: 'AUD',
                    })
                  : 'N/A'}
              </Typography>
            </div>
          );
        },
        display: 'flex',
      },
      {
        field: 'leExclusive',
        headerName: 'Lux Exclusive',
        editable: true,
        display: 'flex',
      },
      {
        field: 'id',
        headerName: 'Preview on:',
        editable: true,
        renderCell: (params) => {
          return (
            <a target="_blank" rel="noreferrer" href={`${customerPortalHost(tenant)}/cruises/${params.value}`}>
              {tenant.title}
            </a>
          );
        },
        display: 'flex',
      },
    ],
    [tenant],
  );

  const mapOffersToDisplay = useMemo(() => {
    return (
      offers?.map((offer) => ({
        names: { name: offer.name, displayName: offer.displayName, id: offer.id },
        shipName: offer.ship.name,
        ...offer,
      })) || []
    );
  }, [offers]);

  const { enqueueSnackbar } = useSnackbar();

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<CruisesContract.OfferStatus>('APPROVED');

  const onSubmit = async (): Promise<void> => {
    const offers = selectedRows.length ? selectedRows.map((cruise) => ({ id: cruise, status: status })) : [];

    setLoading(true);

    const result = await cruiseOfferService.setOffersToApproved({
      offers,
    });

    if (result.status === 200) {
      setLoading(false);
      enqueueSnackbar('The selected Offers were updated successfully.', {
        variant: 'success',
      });
    } else {
      setLoading(false);
      enqueueSnackbar('The selected Offers update failed!', {
        variant: 'success',
      });
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as CruisesContract.OfferStatus);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid width="100%" mb={2} item>
          <Box
            sx={{
              width: '100%',
              borderRadius: '8px',
              padding: '32px 24px 16px 24px',
              backgroundColor: '#fafafa',
              border: '1px solid #f2f2f2',
            }}
          >
            <Stack direction="row" spacing={3}>
              <Box width="85%">
                <FormControl fullWidth>
                  <InputLabel>Offer Status</InputLabel>
                  <Select
                    fullWidth
                    value={status}
                    label="Offer Status"
                    defaultValue="APPROVED"
                    onChange={handleChange}
                    disabled={!selectedRows.length || loading}
                  >
                    <MenuItem value={'APPROVED'}>APPROVED</MenuItem>
                    <MenuItem value={'DRAFT'}>DRAFT</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="overline">Use to update the status of multiple offers</Typography>
              </Box>

              <Box flex={1}>
                <Button size="large" disabled={!selectedRows.length || loading} variant="contained" onClick={onSubmit}>
                  {loading ? 'Saving...' : 'Save Changes'}
                </Button>
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <DataGrid
        loading={loadingOffers}
        paginationModel={{ page, pageSize: cruisesPerPage }}
        pageSizeOptions={[cruisesPerPage]}
        onPaginationModelChange={({ page }) => paginate(page)}
        rows={mapOffersToDisplay}
        columns={columns}
        checkboxSelection
        autoPageSize
        rowCount={total}
        pagination
        paginationMode="server"
        onRowSelectionModelChange={(item) => setSelectedRows(item as string[])}
      />
    </Box>
  );
};

export default withTenant(CruisesList);
