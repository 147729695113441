import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import {
  CruiseDealInclusion,
  CruiseDealPage,
  CruiseDealPageBase,
  CruiseDealPageCreation,
  CruiseDealPageListQuery,
  CruiseDealPageUpdate,
} from '~/components/Cruises/pages/DealsPage/types';

import cruisesAPI from './CruisesApiService';

const RESOURCE = 'deals';

const getById = async (id: string): Promise<API.Response<CruiseDealPage>> => {
  return await cruisesAPI.http.get({ resource: `${RESOURCE}/${id}` });
};

const listWithPagination = async (
  paginationParams: CruiseDealPageListQuery,
): Promise<API.Response<Array<CruiseDealPageBase>>> => {
  return await cruisesAPI.http.get({ resource: RESOURCE, paginationParams }, undefined);
};

const deleteById = async (id: string) => {
  await cruisesAPI.http.delete({
    resource: `${RESOURCE}/${id}`,
  });
};

const create = async (formValues: CruiseDealPageCreation) => {
  await cruisesAPI.http.post({
    resource: RESOURCE,
    body: formValues,
  });
};

const update = async (id: string, formValues: CruiseDealPageUpdate) => {
  await cruisesAPI.http.put({
    resource: `${RESOURCE}/${id}`,
    body: formValues,
  });
};

// Deal inclusions

const getDealInclusions = async (dealId: string): Promise<API.Response<Array<CruiseDealInclusion>>> => {
  return cruisesAPI.http.get({
    resource: `${RESOURCE}/${dealId}/inclusions`,
  });
};

const getDealInclusionById = async (dealId: string, id: string): Promise<CruiseDealInclusion> => {
  const response = await cruisesAPI.http.get<CruiseDealInclusion>({
    resource: `${RESOURCE}/${dealId}/inclusions/${id}`,
  });

  return response.result;
};

const createDealInclusion = async (
  dealId: string,
  formData: Partial<CruiseDealInclusion>,
): Promise<CruiseDealInclusion> => {
  return await cruisesAPI.http.post({
    resource: `${RESOURCE}/${dealId}/inclusions`,
    body: formData,
  });
};

const deleteDealInclusion = async (dealId: string, id: string): Promise<API.Response<Array<CruiseDealInclusion>>> => {
  return cruisesAPI.http.delete({
    resource: `${RESOURCE}/${dealId}/inclusions/${id}`,
  });
};

const updateDealInclusion = async (
  dealId: string,
  id: string,
  formData: Partial<CruiseDealInclusion>,
): Promise<CruiseDealInclusion> => {
  const response = await cruisesAPI.http.put<CruiseDealInclusion>({
    resource: `${RESOURCE}/${dealId}/inclusions/${id}`,
    body: formData,
  });

  return response.result;
};

const sortDealInclusions = async (
  dealId: string,
  dealInclusionIds: Array<string>,
): Promise<API.Response<Array<CruiseDealInclusion>>> => {
  return cruisesAPI.http.put({
    resource: `${RESOURCE}/${dealId}/inclusions-sorting`,
    body: { dealInclusionIds },
  });
};

export default {
  getById,
  listWithPagination,
  deleteById,
  create,
  update,
  getDealInclusions,
  getDealInclusionById,
  createDealInclusion,
  deleteDealInclusion,
  updateDealInclusion,
  sortDealInclusions,
};
