import React, { useCallback, useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarRateIcon from '@mui/icons-material/StarRate';
import { Box, Button, Tooltip } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';
import Spinner from '~/components/Common/Spinner';
import { CruiseDealInclusion } from '~/components/Cruises/pages/DealsPage/types';

import dealPageService from '~/services/cruises/DealPageService';

interface Props {
  inclusion: CruiseDealInclusion;
  onChange: () => void;
}

function DealInclusionListItem({ inclusion, onChange }: Props) {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id: inclusion.id });
  const { enqueueSnackbar } = useSnackbar();
  const { id: dealPageId } = useParams<{ id: string }>();
  const [isUpdating, setIsUpdating] = useState(false);

  const showConfirmDialog = useConfirmDialog();

  const handleDeleteRecord = useCallback(async () => {
    try {
      const confirmed = await showConfirmDialog({
        title: 'Delete Inclusion?',
        description: `This will delete the inclusion, are you sure you want to proceed?`,
      });

      if (!confirmed) {
        return;
      }

      await dealPageService.deleteDealInclusion(dealPageId, inclusion.id);
      enqueueSnackbar('Inclusion deleted', { variant: 'success' });
      onChange();
    } catch (error) {
      enqueueSnackbar('Error deleting Inclusion', { variant: 'error' });
    }
  }, [enqueueSnackbar, dealPageId, inclusion.id, showConfirmDialog]);

  const handleHighlightInclusion = useCallback(async () => {
    try {
      setIsUpdating(true);

      const isHighlighted = !inclusion.isHighlighted;
      await dealPageService.updateDealInclusion(dealPageId, inclusion.id, {
        isHighlighted,
      });
      enqueueSnackbar(isHighlighted ? 'Inclusion highlighted' : 'Inclusion removed from highlight', {
        variant: 'success',
      });
      onChange();
    } catch (error) {
      enqueueSnackbar('Error highlighting Inclusion', { variant: 'error' });
    } finally {
      setIsUpdating(false);
    }
  }, [enqueueSnackbar, dealPageId, inclusion.id, inclusion.isHighlighted]);

  return (
    <ListItem
      key={inclusion.id}
      {...attributes}
      sx={{
        flexGrow: 1,
        transform: CSS.Transform.toString(transform),
        transition,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box ref={setNodeRef} {...listeners} sx={{ cursor: isDragging ? 'grabbing' : 'grab' }} mr={2}>
        <DragIndicatorIcon />
      </Box>

      <ListItemText primary={inclusion.description} secondary={`Icon: ${inclusion.icon}`} />

      <Tooltip title={inclusion.isHighlighted ? 'Remove highlight' : 'Display inclusion highlighted on the deal page'}>
        <Button variant="text" size="small" color="info" onClick={handleHighlightInclusion} disabled={isUpdating}>
          {isUpdating ? <Spinner size={16} /> : inclusion.isHighlighted ? <StarRateIcon /> : <StarOutlineIcon />}
        </Button>
      </Tooltip>

      <Tooltip title="Delete inclusion">
        <Button variant="text" size="small" color="error" onClick={handleDeleteRecord}>
          <DeleteIcon />
        </Button>
      </Tooltip>
    </ListItem>
  );
}

export default DealInclusionListItem;
